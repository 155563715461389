import React from "react"
import * as styles from "./gallery.module.scss"
import Img from "gatsby-image";
import { ParallaxProvider, Parallax } from 'react-scroll-parallax';

export default function Gallery ({data}){
    const images = data.lifestyle.edges

    return <div className={styles.center}>
    <div className={styles.grid}>
        {images.map( (image,i)  => 
        { return <div className={styles.item} >
                        <Parallax speed={0}><Img key={i} fluid={image.node.childImageSharp.fluid}></Img></Parallax>
                    </div>
        }
        )}

    </div>
    </div>
    
};